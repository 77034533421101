import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { Link } from "gatsby"
import { AffiliateList } from "../components/AffiliateList"
import clinicDoor from "../assets/images/clinic-door.webp"
import callWhite from "../assets/icons/call-white.svg"
import locationOnWhite from "../assets/icons/locationOnWhite.svg"
import mailWhite from "../assets/icons/mailWhite.svg"
import clinicDoorX2 from "../assets/images/clinic-door-x2.webp"
import containerClasses from "../styles/container.module.css"
import buttons from "../styles/buttons.module.css"

const Contact = ({ data }) => {
  const [termsState, setTermsState] = useState(false)

  return (
    <Layout page="Contact">
      <SEO
        title="Contact Miss Hawkes | Oculoplastic Surgeon & Aesthetic Practitioner"
        description="Are you looking to book a consultation, or do you need to contact Miss Hawkes? Get in touch today, and a team member will reply within one working day."
      />
      <div className="pt-16 text-center bg-white lg:pt-20">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
            <p className="mb-0 overline">Contact</p>
            <h1 className="mt-4 text-blackNew fluid-display-02">
              Get in touch
            </h1>
            <p className="mt-0 mb-4 text-sm leading-tight text-blackNew lg:col-span-6 lg:col-start-4">
              If you would like to make an appointment or have an enquiry,
              please contact us using one of the methods below. A member of the
              team will reply within one working day.
            </p>
          </div>
          <figure className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
            <div className="transform translate-y-1/2">
              <img
                className="w-full -mt-16 md:-mt-40 lg:-mt-48 xl:-mt-64"
                loading="lazy"
                alt="Dr Hawkes"
                src={clinicDoor}
                srcSet={`${clinicDoor} 1x, ${clinicDoorX2} 2x`}
              />
            </div>
          </figure>
        </div>
      </div>
      <div className="pt-24 pb-12 bg-white2 md:pt-25p lg:pb-40 xl:-mt-12">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <h2 className="mt-0 fluid-heading-04 xl:pt-8">Contact Details</h2>
            <div className="flex mt-4">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-2 lg:mr-4 lg:w-12 lg:h-12 bg-royalBlue">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={mailWhite}
                  alt="Star"
                />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-wider uppercase">
                  Email
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${data?.site?.siteMetadata?.email}`}
                  className="mt-1 font-serif text-xl lg:mt-2 lg:text-xl"
                >
                  {data?.site?.siteMetadata?.email}
                </a>
              </div>
            </div>
            <div className="flex mt-6 lg:mt-12">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-2 lg:mr-4 lg:w-12 lg:h-12 bg-royalBlue">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={callWhite}
                  alt="Phone"
                />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-wider uppercase">
                  Phone Number
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`tel:${data?.site?.siteMetadata?.telephone}`}
                  className="mt-1 font-serif text-xl lg:mt-2 lg:text-xl"
                >
                  {data?.site?.siteMetadata?.telephone}
                </a>
              </div>
            </div>
            <div className="flex mt-6 lg:mt-12">
              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-2 lg:mr-4 lg:w-12 lg:h-12 bg-royalBlue">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={locationOnWhite}
                  alt="Star"
                />
              </div>
              <div>
                <h3 className="mt-0 mb-0 font-mono text-sm tracking-wider uppercase">
                  Clinic Address
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/120+Sloane+St,+Chelsea,+London+SW1X+9BW/@51.4944335,-0.1607926,17z/data=!3m1!4b1!4m5!3m4!1s0x4876053e0792d337:0xc4910b4996eba637!8m2!3d51.4944302!4d-0.1585986/"
                  className="mt-1 font-serif text-xl lg:mt-2 lg:text-xl"
                >
                  {data?.site?.siteMetadata?.address}
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-4 mt-8 lg:row-span-1 md:col-span-8 lg:col-span-6 lg:col-start-7 lg:mt-0 xl:pt-8">
            <h2 className="mt-0 fluid-heading-04">Contact Form</h2>
            <form
              name="contact"
              className="mt-8"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div>
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="first-name"
                >
                  Full Name
                </label>
                <input
                  className="block w-full h-10 bg-transparent border-black border-solid border-b-1"
                  id="first-name"
                  name="firstName"
                  type="text"
                  required
                />
              </div>
              <div className="mt-6">
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  className="block w-full h-10 bg-transparent border-black border-solid border-b-1"
                  id="email"
                  name="email"
                  type="email"
                  required
                />
              </div>
              <div className="mt-6">
                <label
                  className="font-mono text-sm tracking-wider uppercase"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="block w-full h-20 bg-transparent border-black border-solid border-b-1"
                  id="message"
                  name="message"
                />
              </div>
              <button
                id="get-in-touch-submit"
                className={`${buttons.btnLargeExpressive} mt-4 w-full md:w-auto lg:w-fit mb-2`}
                type="submit"
                disabled={!termsState}
              >
                <div className="flex items-center justify-between">
                  Submit
                  <ArrowRight className="ml-10" size="16" />
                </div>
              </button>
            </form>
            <input
              type="checkbox"
              id="agreeterms"
              name="agreeterms1"
              value="consent"
              onChange={e => setTermsState(e.target.checked)}
              className="mr-1"
            ></input>
            <label htmlFor="agreeterms1" className="text-sm">
              I agree to the{""}
              <Link
                className="ml-1 text-sm underline text-gold"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
              {""} and {""}
              <Link
                className="text-sm underline text-gold"
                to="/terms-and-conditions"
              >
                Terms &amp; Conditions
              </Link>
            </label>
            <br></br>
          </div>
        </div>
      </div>
      <AffiliateList />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        telephone
        email
        address
      }
    }
  }
`
